import { useState, useEffect } from "react";
import { Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/framed2.png";
import prayerImg from "../assets/img/jc_wealth_prayer.png";
import tencommandmentsImg from "../assets/img/tencommandments.png";
import originImg from "../assets/img/toly_and_jc.png";
import lastsupperImg from "../assets/img/lastsupper.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

import navIcon1 from '../assets/img/nav-icon1 black.svg';
import navIcon2 from '../assets/img/telegramsvg.svg';
import navIcon3 from '../assets/img/dexscreener.svg';
import navIcon4 from '../assets/img/solscan.svg';
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";

import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/memes/badluck.jpg";
import projImg2 from "../assets/img/memes/meme4_button.jpg";
import projImg3 from "../assets/img/memes/thebibleofjesuscat.jpg";
import projImg4 from "../assets/img/memes/meme5_excuseme.png";
import projImg5 from "../assets/img/memes/meme3_intheroom.jpg";
import projImg6 from "../assets/img/memes/bro.jpg";
import projImg7 from "../assets/img/memes/meme1_anakin.jpg";
import projImg8 from "../assets/img/memes/meme2_theydontknow.jpg";
import projImg9 from "../assets/img/memes/dominos.jpg";
import projImg10 from "../assets/img/memes/papi.PNG";
import projImg11 from "../assets/img/memes/praisehim.jpg";


import on_the_mount from "../assets/img/on_the_mount.png";
import money from "../assets/img/fancymoney.png";
import praying from "../assets/img/praying_jesus_cat.png";
import siting from "../assets/img/siting.webp";

export const Banner = () => {
  
  const memes = [
    {
      imgUrl: projImg1,
    },
    {
      imgUrl: projImg2,
    },
    {
      imgUrl: projImg3,
    },
    {
      imgUrl: projImg4,
    },
    {
      imgUrl: projImg5,
    },
    {
      imgUrl: projImg6,
    },
    {
      imgUrl: projImg7,
    },
    {
      imgUrl: projImg8,
    },
    {
      imgUrl: projImg9,
    },
    {
      imgUrl: projImg10,
    },
    {
      imgUrl: projImg11,
    }
  ];

  return (
    <section className="banner" id="home">
      <div className="animate__animated animate__zoomIn">

                <div className="origin_section">
                <h1><br/>
                  CLUE:</h1>
                <div className="origin">
                  <br></br>
                  <p>
Am I a bricklayer,
a star, a princess, 
an angel? Am I something else?
Whatever the case,
you must learn 
from your errors.</p>
                </div>
                </div>
                </div>
                

    </section>
  )
}
